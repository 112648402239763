import React from 'react';
import { NavBar, Mask } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import { LoadingView } from '@component';
import { getTrueUrl } from '@util/GameUtil';

import './styles.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // 是否加载中
            gameNa: '', // 游戏名字
            gameVer: null, // 游戏版本
            isInterAd: false, // 是否展示插屏广告
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {

    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 从服务器请求数据
        this.requestFromServer();
        // 刷新界面
        this.updateView();

        //为了避免作用域及缓存
        // let self = this;
        // 展示对话框
        window.showInterAd = () => {
            // // 展示插屏广告
            // self.setState({ isInterAd: true }, () => {
            // })
        }
        if (window.googletag) {
            // 判断域名
            if (window.curDomain === window.mrgameOnline) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } else {
                window.googletag.cmd.push(function () {
                    // 判断是否存在（插屏广告）
                    if (window.slotIntl) {
                        console.log('展示插屏广告--游戏内')
                        // 展示 广告
                        window.googletag.display(window.slotIntl);
                    }

                    if (window.soltBnr) {
                        console.log('存在banner')
                    } else {
                        console.log('不存在banner')
                    }
                    // 展示banner广告
                    window.googletag.display("div-gpt-ad-1728833499988-0");
                    // 刷新banner广告
                    window.googletag.pubads().refresh([window.soltBnr]);
                    console.log('展示游戏内banner')
                });
            }
        }
    }
    // 刷新界面
    updateView() {
        // 获取游戏数据
        const { zn } = this.props.match.params;
        // 是否存在数据
        if (zn && zn.length > 0) {
            // 解析数据
            const data = zn.split('__aa__');
            // 校验数据
            if (data && data.length === 2) {
                // 刷新数据
                this.setState({ gameNa: data[1], gameVer: data[0] });
            }
        }
    }
    render() {
        // 获取数据
        const { isLoading, isInterAd, gameNa, gameVer } = this.state;
        return (
            <div className="gameplay-page">
                <div className='sticky'>
                    <NavBar
                        className='navBar'
                        onBack={() => {
                            window.history.back(-1);
                        }}
                        style={{
                            '--height': '50px',
                        }}
                    >{gameNa ? gameNa : ''}</NavBar>
                </div>
                <LoadingView visible={isLoading} />
                {
                    gameVer ?
                        <iframe
                            id='gameIframe'
                            className='gameif'
                            src={getTrueUrl(3, gameVer)}
                            title={gameNa ? gameNa : ''}
                            width="100%"
                            style={{ border: 'none' }}
                        />
                        :
                        null
                }
                {
                    window.curDomain === window.mrgameOnline ?
                        <ins className="adsbygoogle"
                            style={{ display: 'inline-block', width: '320px', height: '50px' }}
                            data-ad-client="ca-pub-9070575352006124"
                            data-ad-slot="4316483752"></ins>
                        :
                        <div id="div-gpt-ad-1728833499988-0" style={{ minWidth: '320px', minHeight: '50px', marginLeft: '0px' }}></div>

                }
                {
                    isInterAd ?
                        <Mask visible={isInterAd} onMaskClick={() => {
                            // this.setState({ isInterAd: false })
                        }}>
                            <div className='overlayContent'>
                                {/* <div id="banner-ad1" style={{ width: '750px', height: '200px' }}></div> */}
                            </div>
                        </Mask>
                        :
                        null
                }
            </div>
        )
    }
}

export default withRouter(IndexPage)