import { useEffect } from 'react'

const Main = (props) => {
    // 跳转路由，页面滑动回到顶部
    useEffect(() => {
        document.body.scrollIntoView()
    }, [props.location.pathname])

    return (
        props.children
    )
}
export default Main