// 全局配置
const globalCtg = {
    color_theme: '#17a0f7', // 主题颜色
    unknownErrorID: 99999, // 未知错误

    // 列表类型
    listType: {
        all: 100, // 所有
        hots: 101, // 最热
        news: 102, // 最新
        push: 103, // 推荐
        last: -100, // 最近
    },
    // banner的Url
    // bannerUrl: 'https://battle.xiaoyouxi666.com:2201/hfbox/banner/xxx.jpg',
    // icon的Url
    // iconUrl: 'https://battle.xiaoyouxi666.com:2201/hfbox/area/xxx.jpg',
    // 游戏入口
    // gameUrl: 'https://battle.xiaoyouxi666.com:2201/hfbox/game/xxx/index.html',
}

export const GlobalCtg = globalCtg;
