import React from "react";
import { SpinLoading } from "antd-mobile";
import "./styles.less";

const IndexComponent = (props) => {
    const { visible } = props;
    return (
        visible ?
            <div className='loading'>
                <SpinLoading color='primary' style={{ '--size': '48px' }} />
            </div>
            :
            null
    )
}

export default IndexComponent;