import React from 'react';
import { NavBar } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import './styles.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            privacyText: '', // 是否加载中
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {
        // fetch('/Privacy.txt')
        //     .then(response => response.text())
        //     .then(text => this.setState({ privacyText: text }))
        //     .catch(error => console.error('Error fetching text file:', error));
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 从服务器请求数据
        this.requestFromServer();
    }
    render() {
        // 获取数据
        // const { privacyText } = this.state;
        return (
            <div className="privacy-page">
                <div className='sticky'>
                    <NavBar
                        className='navBar'
                        onBack={() => {
                            window.history.back(-1);
                        }}
                        style={{
                            '--height': '50px',
                        }}
                    >Privacy</NavBar>
                </div>
                <iframe
                    id='privacyIframe'
                    className='privacyif'
                    src='https://mrgame.online/Privacy_policy.html'
                    title='privacy'
                    width="100%"
                    style={{ border: 'none' }}
                />
                {/* <div className='descTx'>{privacyText}</div> */}
            </div>
        )
    }
}

export default withRouter(IndexPage)