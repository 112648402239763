const httpDomain_localhost = "https://192.168.50.98:2201"; // 本地测试（内网测试）
const httpDomain_test = 'https://battle.xiaoyouxi666.com:2201'; // 内网测试
const httpDomain_production = 'https://ca.cash-wall.com:2201'; // 外网正式

// 加解密的密钥
const aesKey = "ookd3dsddf37kd93";
// 加解密的变量
const aesIvParam = "8654952318846859";

export const Http_Domain_Localhost = httpDomain_localhost;
export const Http_Domain_Test = httpDomain_test;
export const Http_Domain_Production = httpDomain_production;

export const Aes_Key = aesKey;
export const Aes_IvParam = aesIvParam;

