import React from 'react';
import { Swiper, Image, Rate, Divider } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import { LoadingView, GameIcon } from '@component';
import { IMAGE } from "@/assets";
import { GlobalCtg } from "@config/config";
import { doQueryList } from '@util/HttpUtil';
import { saveHotListToLocal, getLastGameList, getTrueUrl } from '@util/GameUtil';
import './styles.less';

/**
 * 游戏盒子列表
 */
function GamesBox(props) {
    // 上下文
    let that = props.that;
    // 获取游戏列表
    let list = props.games;
    // 列表类型
    let lType = props.lty;
    // 标题
    let title = 'All games';
    // 判断类型
    if (lType === GlobalCtg.listType.hots) {
        title = 'Hot games';
    } else if (lType === GlobalCtg.listType.news) {
        title = 'New games';
    } else if (lType === GlobalCtg.listType.last) {
        title = 'Played games';
    }
    // 返回界面
    return < >
        {
            list && list.length > 0 ?
                <div className='boxLyr'>
                    <div className='titleLyr'>
                        <div className='titleTx'>{title} </div>
                        <div className='moreTx' onClick={() => {
                            // 展示更多游戏
                            that.showMoreGames(lType);
                        }}>{"Show more >>"} </div>
                    </div>
                    <div className='rowLyr'>
                        <GameIcon that={that} data={list[0]} />
                        <GameIcon that={that} data={list[1]} />
                    </div>
                    {
                        list[2] ?
                            <div className='rowLyr'>
                                <GameIcon that={that} data={list[2]} />
                                <GameIcon that={that} data={list[3]} />
                            </div>
                            :
                            null
                    }
                </div>
                :
                null
        }
    </>
}

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // 是否加载中
            pushList: null, // 推荐列表
            lastList: null, // 最近列表
            hotList: null, // 最热列表
            newList: null, // 最新列表
            allList: null, // 全部列表
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {
        // 展示loading
        this.setState({ isLoading: true });
        // 1.获取推荐列表
        this.loadGameListByType(GlobalCtg.listType.push, () => {
            // 2.获取最热列表
            this.loadGameListByType(GlobalCtg.listType.hots, () => {
                // 3.获取最新列表
                this.loadGameListByType(GlobalCtg.listType.news, () => {
                    // 4.获取全部列表
                    this.loadGameListByType(GlobalCtg.listType.all, () => {
                        // 关闭loading
                        this.setState({ isLoading: false });
                    })
                })
            })
        })
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 从服务器请求数据
        this.requestFromServer();
        // 判断域名
        if (window.curDomain === window.mrgameOnline) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } else {
            if (window.googletag) {
                window.googletag.cmd.push(function () {
                    // 展示广告
                    window.googletag.display("div-gpt-ad-1728542172980-0");
                    window.googletag.display("div-gpt-ad-1728542223048-0");
                    window.googletag.display("div-gpt-ad-1728542278232-0");
                    // 刷新广告
                    window.googletag.pubads().refresh([window.solt1, window.solt2, window.solt3]);
                });
            }
        }
    }
    /**
     * 根据类型加载游戏列表
     * @param {*} _ty ：100是全部，101是最热，102是最新，103是推荐
     * @param {*} _cb ：回调
     */
    loadGameListByType(_ty, _cb) {
        // 执行查询
        doQueryList(_ty, 1, (_resultObj) => {
            // 判断是否挂载
            if (!this.mounted) {
                // 回调
                _cb && _cb();
                return
            }
            // 判断状态
            if (_resultObj.s === 0) {
                // 判断是否有数据
                if (_resultObj.data && _resultObj.data.list && _resultObj.data.list.length > 0) {
                    // 当前数据
                    let list = _resultObj.data.list;
                    // 判断类型
                    if (_ty === GlobalCtg.listType.all) { // 全部 
                        // 刷新数据
                        this.setState({ allList: list.slice(0, 4) });
                    } else if (_ty === GlobalCtg.listType.hots) { // 最热 
                        // 保存数据到本地
                        saveHotListToLocal(list);
                        // 刷新数据
                        this.setState({ hotList: list.slice(0, 4) });
                    } else if (_ty === GlobalCtg.listType.news) { // 最新
                        // 刷新数据
                        this.setState({ newList: list.slice(0, 4) });
                    } else if (_ty === GlobalCtg.listType.push) { // 推荐 
                        // 获取最近列表
                        var locallist = getLastGameList();
                        // 判断是否存在
                        if (!locallist) {
                            locallist = [];
                        }
                        // 刷新数据
                        this.setState({ pushList: list, lastList: locallist.slice(0, 4) });
                    }
                }
            }
            // 回调
            _cb && _cb();
        });
    }
    /**
     * 展示更多游戏列表
     * @param {*} _ty ：类型 100是全部，101是最热，102是最新，103是推荐
     */
    showMoreGames(_ty) {
        // 展示游戏列表
        let path = {
            pathname: '/home/gamelist/' + _ty,
        }
        // 跳转
        this.props.history.push(path);
    }
    /**
     * 展示游戏详情
     * @param {*} _gid ：游戏id
     */
    showGameDetail(_gid) {
        // 展示游戏列表
        let path = {
            pathname: '/home/details/' + _gid,
        }
        // 跳转
        this.props.history.push(path);
    }
    render() {
        // 获取数据
        const { isLoading, pushList, lastList, hotList, newList, allList } = this.state;
        return (
            <div className="home-page">
                <div className='sticky'>
                    <div className='navBarLay'>
                        <img className='logo' src={IMAGE['hfboxLogo']} alt="" />
                    </div>
                </div>
                <LoadingView visible={isLoading} />
                {
                    pushList && pushList.length > 0 ?
                        <>
                            <Swiper
                                className='swiperLyr'
                                loop
                                autoplay
                                style={{
                                    '--track-padding': ' 0 0 16px',
                                }}
                            >
                                {
                                    pushList.map((_item, index) => (
                                        <Swiper.Item className='swiperItem' key={index} onClick={() => {
                                            // 展示游戏详情
                                            this.showGameDetail(_item.gid);
                                        }}>
                                            <div className='bannerLyr'>
                                                <Image src={getTrueUrl(1, _item.ic2)} fit='fill' />
                                                <div className='msgLyr' >
                                                    <div className='nameTx'>{_item.na}</div>
                                                    <div className='starLyr'>
                                                        <Rate
                                                            allowHalf
                                                            readOnly
                                                            defaultValue={_item.sn / 10}
                                                            style={{
                                                                '--star-size': '24px',
                                                                '--active-color': '#f23e6c',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Swiper.Item>
                                    ))
                                }
                            </Swiper>

                        </>

                        :
                        null
                }
                {
                    window.curDomain === window.mrgameOnline ?
                        <ins className="adsbygoogle"
                            style={{ display: 'inline-block', width: '300px', height: '250px' }}
                            data-ad-client="ca-pub-9070575352006124"
                            data-ad-slot="5831739818"></ins>
                        :
                        <div id="div-gpt-ad-1728542172980-0" style={{ minWidth: '300px', minHeight: '250px', marginLeft: '0px' }}></div>

                }
                {
                    lastList && lastList.length > 0 ?
                        <>
                            <Divider className='divider' />
                            <GamesBox that={this} games={lastList} lty={GlobalCtg.listType.last} />
                        </>
                        :
                        null
                }
                <Divider className='divider' />
                <GamesBox that={this} games={hotList} lty={GlobalCtg.listType.hots} />
                {
                    window.curDomain === window.mrgameOnline ?
                        <ins className="adsbygoogle"
                            style={{ display: 'inline-block', width: '300px', height: '250px' }}
                            data-ad-client="ca-pub-9070575352006124"
                            data-ad-slot="1892494801"></ins>
                        :
                        <div id="div-gpt-ad-1728542223048-0" style={{ minWidth: '300px', minHeight: '250px', marginLeft: '0px' }}></div>

                }
                <Divider className='divider' />
                <GamesBox that={this} games={newList} lty={GlobalCtg.listType.news} />
                {
                    window.curDomain === window.mrgameOnline ?
                        <ins className="adsbygoogle"
                            style={{ display: 'inline-block', width: '300px', height: '250px' }}
                            data-ad-client="ca-pub-9070575352006124"
                            data-ad-slot="6953249799"></ins>
                        :
                        <div id="div-gpt-ad-1728542278232-0" style={{ minWidth: '300px', minHeight: '250px', marginLeft: '0px' }}></div>

                }
                <Divider className='divider' />
                <GamesBox that={this} games={allList} lty={GlobalCtg.listType.all} />
                <div className='privacyLry'
                    onClick={() => {
                        // 展示协议
                        let path = {
                            pathname: '/home/privacy/',
                        }
                        // 跳转
                        this.props.history.push(path);
                    }}>Privacy</div>
            </div>
        )
    }
}

export default withRouter(IndexPage)