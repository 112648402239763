import React from "react";
import { Image, Rate } from 'antd-mobile';
import { getTrueUrl } from '@util/GameUtil';
import "./styles.less";

const IndexComponent = (props) => {
    const { data, that } = props;
    // 展示详情
    const showGameDetail = function (_gid) {
        // 展示游戏列表
        let path = {
            pathname: '/home/details/' + _gid,
        }
        // 跳转
        that.props.history.push(path);
    }
    return (
        data ?
            <div className='__IconLyr' onClick={() => {
                // 展示游戏详情
                showGameDetail(data.gid);
            }}>
                <Image src={getTrueUrl(2, data.ic1)} fit='fill' />
                <div className='nameTx'>{data.na}</div>
                <div className='starLyr'>
                    <Rate
                        allowHalf
                        readOnly
                        defaultValue={data.sn / 10}
                        style={{
                            '--star-size': '16px',
                            '--active-color': '#f23e6c',
                        }}
                    />
                </div>
            </div>
            :
            null
    )
}

export default IndexComponent;
