import React from "react";
import { ErrorBlock } from "antd-mobile";
import "./styles.less";

const IndexComponent = (props) => {
    const { visible, isFull } = props;
    return (
        visible ?
            <ErrorBlock status='empty' fullPage={isFull} title='没有数据' description='请稍后再查看！' />
            :
            null
    )
}

export default IndexComponent;
